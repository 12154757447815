import car1 from "./assests/car0.webp";
import car2 from "./assests/car1.webp";
import car3 from "./assests/car3.webp";
//Showroom Images
import showroom1 from "./assests/showroom/showroom1.webp";
import showroom from "./assests/showroom/showroom.webp";
import showroom5 from "./assests/showroom/showroom7.webp";
import showroom2 from "./assests/showroom/showroom3.webp";
import showroom7 from "./assests/showroom/showroom5.webp";
import showroom8 from "./assests/showroom/showroom8.webp";
//Audio products
import artBoard1 from "./assests/webp/Artboard 1.webp";
import artBoard2 from "./assests/webp/Artboard 2.webp";
import artBoard3 from "./assests/webp/Artboard 3.webp";
import artBoard4 from "./assests/webp/Artboard 4.webp";
import artBoard5 from "./assests/webp/Artboard 5.webp";
import artBoard6 from "./assests/webp/Artboard 6.webp";
import artBoard7 from "./assests/webp/Artboard 7.webp";
import artBoard9 from "./assests/webp/Artboard 9.webp";
import image4 from "./assests/webp/image4.webp";
import image5 from "./assests/webp/image5.webp";
import image6 from "./assests/webp/image6.webp";
import image7 from "./assests/webp/image7.webp";
import image10 from "./assests/webp/image10.webp";
import image11 from "./assests/webp/image11.webp";
import image12 from "./assests/webp/image12.webp";
import image13 from "./assests/webp/image13.webp";
import image14 from "./assests/webp/image14.webp";
//Light products
import image1 from "./assests/webp/image1.webp";
import image8 from "./assests/webp/image8.webp";
import image9 from "./assests/webp/image9.webp";
//motors
import artBoard8 from "./assests/webp/Artboard 8.webp";
import artBoard10 from "./assests/webp/Artboard 10.webp";
//Camera
import image3 from "./assests/webp/image3.webp";

export const EdgeData = [
    {
        id:1,
        img : image1,
        product: 'Light',
        title:'LED HeadLights',
        content:`Upgrade your car's illumination with our high-performance headlights  that incorporate advanced technology, provides  superior brightness and clarity, ensuring optimal visibility in diverse driving conditions.`
    },
    {
        id:2,
        img : image3,
        product : 'Dash Cam',
        title:'Dash Cam',
        content:`Capture every moment on the road with our reliable and feature-packed dash camera. This compact gadget captures high-quality video recordings of your driving escapades, offering an added level of security and reassurance.`
    },
    {
        id:3,
        img : image4,
        title:'Speakers',
        product: 'Audio',
        content:`Enhance your in-car audio experience with our premium audio products. Designed to deliver exceptional sound quality, our car audio systems and speakers provide immersive and powerful audio reproduction.`
    },
];

export const ShowroomImg = [
    {
        id:1,
        img : showroom1,
    },
    {
        id:2,
        img : showroom,
    },
    {
        id:3,
        img : showroom5,
    },
    {
        id:4,
        img : showroom2,
    },
    {
        id:5,
        img : showroom7,
    },
    {
        id:6,
        img : showroom8,
    },
];

export const TestimonialContent = [
    {
        id:1,
        img : artBoard1,
        title:'Hear from our happy clients!',
        content:"I highly recommend Edge Car Care Accessories for their exceptional service and exceptional products. The team went the extra mile in assisting me in finding the ideal car accessories, ensuring a remarkable experience.",
    },
    {
        id:2,
        img : image1,
        title:'Hear from our happy clients!',
        content:"I am extremely happy with my purchase from Edge Car Care Accessories. The products exceeded my expectations, and the customer support was outstanding. I will definitely be coming back as a repeat customer!",
    },
    {
        id:3,
        img : image3,
        title:'Hear from our happy clients!',
        content:"Impressed by the wide selection and competitive prices at Edge Car Care Accessories. The seat covers I ordered fit perfectly and added a stylish touch to my car's interior. Great value for money!",
    },
    {
        id:4,
        img : artBoard8,
        title:'Hear from our happy clients!',
        content:"I recently purchased an audio system from Edge Car Care Accessories, and I'm blown away by the incredible sound quality. The installation was a breeze, and I can now enjoy my favorite music in perfect clarity. Thank you!",
    },
];

export const QuoteImages = [
    {
        id: 1,
        img : car1,
    },
    {
        id: 2,
        img : car2,
    },
    {
        id: 3,
        img : car3,
    },
];

export const brands = [
    {
        id: 1,
        name: 'Audio',
        img : artBoard1,
        title: 'Pioneer',
        desc:'The Pioneer DMH-A245BT is a feature-rich car stereo receiver with a 6.2-inch WVGA touch screen display. It offers Bluetooth connectivity for hands-free calling and wireless audio streaming. The receiver supports various media playback options, including AM/FM radio, CD/DVD discs, and USB connectivity. With smartphone integration capabilities, you can easily access compatible apps and enjoy customized sound settings for an enhanced in-car audio experience.',
    },
    {
        id: 2,
        name: 'Audio',
        img : artBoard2,
        title: 'Onkyo',
        desc:`The Onkyo X-QD 1000 is a sleek and powerful audio device that delivers immersive sound quality. With versatile connectivity options such as Bluetooth and USB,  it seamlessly integrates with your device. Its modern design adds a touch of elegance to any setting, making it a perfect choice for music or movies.`,
    },
    {
        id: 3,
        name: 'Audio',
        img : artBoard3,
        title: 'Pioneer',
        desc:`The Pioneer GM-E7004 is a 4-channel car amplifier that delivers powerful sound performance. With up to 1000 watts RMS per channel, it ensures clear and dynamic audio reproduction. Its compact design enables easy installation in tight spaces. The amplifier offers versatile connectivity options for seamless integration into your car's audio system.`,
    },
    {
        id: 4,
        name: 'Audio',
        img : artBoard4,
        title: 'Sony',
        desc:`The Sony XS-AW8 is a compact and powerful car subwoofer that delivers deep and impactful bass. With its innovative design and advanced technology, it enhances your car audio system, providing a rich and immersive listening experience. The subwoofer is easy to install and compatible with a variety of car models. Perfect for car enthusiasts who crave enhanced low-frequency performance on the go.`,
    },
    {
        id: 5,
        name: 'Audio',
        img : artBoard5,
        title: 'JBL Concert A704',
        desc:`The JBL Concert A704 car amplifier is a reliable and powerful addition to your car audio setup. Engineered to deliver impressive performance, it enhances your audio experience by providing clean and distortion-free sound reproduction. With its robust design and advanced technology, this ensures optimal power delivery, bringing your music to life with clarity and precision.`,
    },
    {
        id: 6,
        name: 'Audio',
        img : artBoard6,
        title: 'Brazo',
        desc:`The Brazo PA360.4 is a high-performance 4-channel amplifier designed to deliver impressive audio power in your car. With its compact yet robust design, it can easily fit into any vehicle. This amplifier provides excellent sound clarity and dynamic range, enhancing your music experience. Its versatile functionality allows you to power multiple speakers or subwoofers, making it an ideal choice for car audio enthusiasts.`,
    },
    {
        id: 7,
        name: 'Audio',
        img : artBoard7,
        title: 'Audible Dynamic NR SERIES',
        desc:'The Audible Dynamic NR Series NR 60C is a remarkable car audio component that brings your in-car sound to a whole new level. With its advanced noise reduction technology, it delivers crystal-clear audio quality and eliminates unwanted background noise. The sleek and compact design allows for easy installation, while the impressive sound reproduction ensures a truly immersive and enjoyable driving experience.',
    },
    {
        id: 8,
        name: 'Audio',
        img : artBoard9,
        title: 'Audison',
        desc:'The Audison APK 165 is a high-performance car speaker system designed to deliver outstanding audio quality. With its advanced technology and precise engineering, it produces a clear and dynamic sound with exceptional detail. The system includes two woofers, two tweeters, and crossovers for optimal sound dispersion. This is the perfect choice for car enthusiasts looking to upgrade their in-car audio experience.',
    },
    {
        id: 9,
        name: 'Audio',
        img : image4,
        title: 'MTX Audio',
        desc:`The MTX Audio TR65C is a set of car speakers engineered to deliver outstanding audio quality. With their durable construction and advanced technology, these speakers provide crisp and clear sound reproduction. The included crossovers ensure accurate frequency separation for an immersive listening experience. This speakers are a perfect choice for car enthusiasts who demand superior audio performance and reliability on the road.
        `,
    },
    {
        id: 11,
        name: 'Audio',
        img : image6,
        title: 'Focal',
        desc:`Experience a new level of in-car audio excellence with the FOCAL RSE-165.
        it delivers immersive sound reproduction and precise sonic imaging. The speakers are built with quality materials and innovative technology for enhanced durability and optimal sound dispersion. Upgrade your car audio system with the FOCAL  for unparalleled sound quality.
       `,
    },
    {
        id: 12,
        name: 'Audio',
        img : image7,
        title: 'JBL Harman',
        desc:'JBL Harman is a renowned brand known for its exceptional audio products. The CS790CHI speaker, with 390 watts of power, delivers a powerful and immersive audio experience. It combines cutting-edge technology and expert craftsmanship to produce rich, clear sound with impressive depth. This  offers an audio solution that exceeds expectations, captivating listeners with its superior performance and fidelity.',
    },
    {
        id: 13,
        name: 'Audio',
        img : image10,
        title: 'Infinity Harman',
        desc:`The 6530 CXF from Infinity Harman is a car speaker system that offers impressive audio quality. With a peak power of 450W, it delivers powerful sound, enriching your in-car experience. The speakers handle dynamic music with precision, delivering deep bass and crisp highs. Upgrade your car audio with this remarkable speaker system.`,
    },
    {
        id: 14,
        name: 'Audio',
        img : image11,
        title: 'Pioneer',
        desc:'The Pioneer TS-J170C is a car speaker system that provides impressive audio performance. These speakers deliver clear and immersive sound with deep bass response, enhancing your music listening experience on the road. The durable construction ensures reliability, while the easy installation process makes upgrading your car audio system effortless. Enjoy the superior audio quality.',
    },
    {
        id: 15,
        name: 'Audio',
        img : image12,
        title: 'Sony',
        desc:`The Sony XAV-AX8100 is a feature-packed car multimedia receiver with a large 8.95-inch touchscreen display for intuitive control and vivid visuals. It offers seamless integration with Apple CarPlay and Android Auto, providing hands-free calling, messaging, and music playback. With high-quality audio playback, navigation, and multiple connectivity options, it's the perfect upgrade for your car's infotainment system.`,
    },
    {
        id: 16,
        name: 'Audio',
        img : image13,
        title: 'UnPlug',
        desc:`The Unplug Android multimedia player is a versatile device that brings advanced entertainment options to your vehicle. With its user-friendly interface and powerful features, you can enjoy seamless integration with your Android device, enabling access to apps, music, videos, and navigation. The high-resolution display ensures crisp visuals, while the intuitive controls and easy installation make it a convenient choice for upgrading your car's multimedia capabilities.`,
    },
    {
        id: 17,
        name: 'Audio',
        img : image14,
        title: 'Blaupunkt',
        desc:`The Blaupunkt New York 750 is an advanced car multimedia system designed to elevate your in-car entertainment experience. With its high-resolution touchscreen display, you can easily navigate through various features and enjoy clear visuals. It supports multiple media formats, offers Bluetooth connectivity for hands-free calling and audio streaming, and features built-in navigation for hassle-free driving. It's the perfect upgrade for a premium multimedia experience.`,
    },
    //Light Products
    {
        id: 10,
        name: 'Light',
        img : image5,
        title: 'Brazo',
        desc:'Automotive-grade LED lights are a popular choice for automotive lighting applications due to their numerous advantages over traditional incandescent bulbs. These LED lights are highly energy-efficient, providing bright illumination while consuming minimal power. They also have a longer lifespan compared to traditional bulbs, reducing the need for frequent replacements.',
    },
    {
        id: 18,
        name: 'Light',
        img : image1,
        title: 'MaxxLink',
        desc:`MAXXLINK LED headlights revolutionize automotive lighting with exceptional brightness and style. These headlights utilize advanced LED technology for brilliant illumination and enhanced visibility. With their sleek design and precise beam patterns, they offer superior lighting performance, ensuring a safer and more enjoyable driving experience. Upgrade your vehicle with MAXXLINK for stunning form and outstanding function.`,
    },
    {
        id: 19,
        name: 'Light',
        img : image8,
        title: 'Excelite',
        desc:`The Excelite Headlight LED E4 series sets a new standard in automotive lighting. With its Pioneering LED technology, these headlights provide superior brightness and enhanced visibility on the road. Designed with precision and durability, and delivers exceptional lighting performance for an elevated driving experience.`,
    },
    {
        id: 20,
        name: 'Light',
        img : image9,
        title: 'Envision',
        desc:`Envision professional LED headlamps are a must-have car accessory for enhanced visibility and style. With their advanced LED technology, these headlamps deliver powerful illumination, improving nighttime driving safety. Their sleek design and easy installation make them a practical and stylish upgrade for any vehicle.`,
    },
    //motors
    {
        id: 21,
        name: 'Motors',
        img : artBoard8,
        title: 'Bergmann',
        desc:`Upgrade your car's inflation capabilities with our heavy-duty metal air compressor. Specifically designed for automotive use, this compressor is built to withstand the demands of on-the-go inflation tasks. Its robust metal construction ensures durability and long-lasting performance, even in rugged environments.`,
    },
    {
        id: 21,
        name: 'Motors',
        img : artBoard10,
        title: 'Jump Starter',
        desc:'Ensure peace of mind on the road with our powerful jump starter. Designed to quickly and safely jump-start your vehicle, this portable device is a must-have for any car owner. With its robust construction and advanced features, you can confidently tackle unexpected battery issues wherever you go.',
    },
    //camera
    {
        id: 21,
        name: 'Dash Camera',
        img : image3,
        title: '70mai',
        desc:`Capture every moment on the road with the 70mai Dash Cam. This compact and feature-packed device offers reliable and high-quality video recording for your driving adventures. Equipped with advanced technology, this Dash Cam records in crisp 1080p Full HD resolution, ensuring clear and detailed footage of the road ahead. Its wide-angle lens provides a broad field of view, capturing more of the surroundings and enhancing your safety on the road.`
        },
];

export const Products = [
    {
        id : '1',
        name : 'Audio'
    },
    {
        id : '2',
        name : 'Light'
    },
    {
        id : '3',
        name : 'Motors'
    },
    {
        id : '4',
        name : 'Dash Camera'
    },
]